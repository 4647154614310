import axios from "axios";

const BASE_URL = process.env.REACT_APP_ROUTE_CONFIG_BASE_URL;

export const getRouteConfig = async () => {
  try {
    const res = await axios.get(`${BASE_URL}/content-configs`);
    return res.data;
  } catch (e) {
    console.log("Error fetching route config", e);
    throw e;
  }
};

