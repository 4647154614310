import React from "react"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import SearchIcon from "@mui/icons-material/Search"
import Menu from "@mui/icons-material/Menu"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import UploadIcon from "@mui/icons-material/Upload"
import CancelIcon from "@mui/icons-material/Cancel"
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft'
import SummarizeIcon from '@mui/icons-material/Summarize'
import PermIdentityIcon from '@mui/icons-material/PermIdentity'
import DataUsageIcon from '@mui/icons-material/DataUsage'
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'
import PlayCircleIcon from '@mui/icons-material/PlayCircle'
import StopCircleIcon from '@mui/icons-material/StopCircle'
import Delete from '@mui/icons-material/Delete'

const DownloadIcon = (props) => {
  return <CloudUploadIcon {...props } style={{ fontSize: "150px", color: "#006bb6", ...props?.style}} />;
}

const DeleteIcon = () => {
  return <Delete style={{ fontSize: "50px", color: "#fff" }} />;
}


const PlayIcon = (props) => {
  return <PlayCircleIcon style={{ fontSize: "60px", color: "#93e937" }} {...props}  />;
}

const StopIcon = () => {
  return <StopCircleIcon style={{ fontSize: "60px", color: "#e95637" }} />;
}

const PhotoIcon = () => {
  return <AddPhotoAlternateIcon style={{ fontSize: "125px", color: "#006bb6" }} />;
}

const TriangleIcon = () => {
  return <ChangeHistoryIcon style={{ fontSize: "15px", color: "#006bb6" }} />;
}

const SummaryIcon = () => {
  return <SummarizeIcon style={{ fontSize: "30px", color: "#006bb6" }} />;
}

const MetaDataIcon = () => {
  return <DataUsageIcon style={{ fontSize: "30px", color: "#006bb6" }} />;
}

const EntityIcon = () => {
  return <PermIdentityIcon style={{ fontSize: "30px", color: "#006bb6" }} />;
}

const RightArrowIcon = () => {
  return <ArrowCircleRightIcon style={{ fontSize: "25px", color: "#006bb6"}} />;
};

const LeftArrowIcon = () => {
  return <ArrowCircleLeftIcon style={{ fontSize: "25px", color: "#006bb6"}} />;
};

const CheckMark = () => {
  return <CheckCircleIcon style={{ fontSize: "25px", color: "#bcd63e" }} />;
};
const Upload = () => {
  return <UploadIcon style={{ fontSize: "60px", color: "#66dbff7e" }} />;
};
const Cancel = () => {
  return <CancelIcon style={{ fontSize: "25px", color: "#C06464" }} />;
};
const Search = (props) => {
  return (
    <SearchIcon style={{ fontSize: "30px", color: props.iconColor }} />
  );
};
const MenuIcon = (props) => {
  return <Menu style={{ fontSize: "55px", color: "#fff" }} />;
};
const MoreVerticalIcon = (props) => {
  return (
    <MoreVertIcon
      style={{ fontSize: props.iconSize, color: props.iconColor }}
    />
  );
};
export const Icon = ({ component, style, ...props }) =>
  component({
    style: {
      width: "33px",
      height: "33px",
      borderRadius: "10px",
      textAlign: "center",
      padding: "5px",
      boxShadow: "#819dadff 1px 2px 4px",
      ...style,
    },
    ...props,
  });
export const DOWNLOAD_ICON = (props) =>
  Icon({ component: DownloadIcon, ...props });
export const SEARCH_ICON = (props) => Icon({ component: Search, ...props });
export const MENU_ICON = (props) => Icon({ component: MenuIcon, ...props });
export const MORE_VERTICAL_ICON = (props) =>
  Icon({ component: MoreVerticalIcon, ...props });
export const UPLOAD_ICON = (props) => Icon({ component: Upload, ...props });
export const CANCEL_ICON = (props) => Icon({ component: Cancel, ...props });
export const CHECK_MARK_ICON = (props) => Icon({ component: CheckMark, ...props });
export const RIGHT_ARROW_ICON = (props) => Icon({ component: RightArrowIcon, ...props });
export const LEFT_ARROW_ICON = (props) => Icon({ component: LeftArrowIcon, ...props })
export const SUMMARY_ICON = (props) => Icon({ component: SummaryIcon, ...props })
export const ENTITY_ICON = (props) => Icon({ component: EntityIcon, ...props })
export const META_DATA_ICON = (props) => Icon({ component: MetaDataIcon, ...props })
export const TRIANGLE_ICON = (props) => Icon({ component: TriangleIcon, ...props })
export const PHOTO_ICON = (props) => Icon({ component: PhotoIcon, ...props })
export const PLAY_ICON = (props) => Icon({ component: PlayIcon, ...props })
export const STOP_ICON = (props) => Icon({ component: StopIcon, ...props })
export const DELETE_ICON = (props) => Icon({ component: DeleteIcon, ...props })