import React from "react";
import { Grid } from "@mui/material";
import SAIC_LOGO from "../assets/images/SAICLogo.png";
import MenuComponent from "../components/menu";

const Header = () => {
  return (
    <div>
      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        display={"flex"}
        alignItems="center"
        justifyContent={"space-between"}
        px={2}
      >
        <Grid>
          <a href={"/"}>
            <img
              src={SAIC_LOGO}
              style={{ height: "40px", margin: 0 }}
              alt="SAIC Logo"
            />
          </a>
        </Grid>

        <Grid>
          <MenuComponent />
        </Grid>
      </Grid>
    </div>
  );
};

export default Header;
