import React, { lazy, Suspense, useMemo } from "react";
import { Grid, CircularProgress } from "@mui/material";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { APIProvider } from "@vis.gl/react-google-maps";

import Header from "./header";
import Footer from "./footer";

import defaultRouteConfig from "../defaultRouteConfig.json";

import { useFetchRouteConfigQuery } from "../queries/routeConfig";

const Upload = lazy(() => import("../upload"));
const ComputerVision = lazy(() => import("../computerVision"));
const Capabilities = lazy(() => import("../capabilities"));
const Translator = lazy(() => import("../translator"));
const SpeechToText = lazy(() => import("../content/speechToText"));
const EdgeCvPage = lazy(() => import("../edgeCv"));
const EmployeeRecognitionPage = lazy(() => import("../employeeRecognition"));
const FacialRecognitionPage = lazy(() => import("../facialRecognition"));

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const PageConfigMap = {
  NLPCapabilities: Upload,
  InstanceSegmentation: ComputerVision,
  VideoInference: Capabilities,
  EdgeCV: EdgeCvPage,
  SpeechToText: SpeechToText,
  AWSFacialRecognition: EmployeeRecognitionPage,
  HVTIdentification: FacialRecognitionPage,
  Translator: Translator,
};

export default ({ children, ...props } = {}) => {
  const { data, isLoading } = useFetchRouteConfigQuery();

  const routeConfig = useMemo(() => data ?? defaultRouteConfig, [data]);

  const HomeComponent =
    PageConfigMap[routeConfig?.homeDefault] ??
    PageConfigMap[defaultRouteConfig.homeDefault];

  return (
    <Grid container item {...props} className="fadePanel">
      <Grid item xs={12} sm={12} md={12} lg={12} className="headerContainer">
        <Header />
      </Grid>

      <Grid
        item
        xs={11}
        sm={11}
        md={12}
        lg={12}
        className="contentContainer fadePanel"
      >
        <APIProvider apiKey={GOOGLE_MAPS_API_KEY}>
          <BrowserRouter>
            <Suspense
              fallback={
                <Grid
                  item
                  xs={12}
                  alignItems={"center"}
                  justifyContent="center"
                  display="flex"
                  flexDirection="column"
                >
                  <CircularProgress />
                </Grid>
              }
            >
              {isLoading ? (
                <Grid
                  item
                  xs={12}
                  alignItems={"center"}
                  justifyContent="center"
                  display="flex"
                  flexDirection="column"
                >
                  <CircularProgress />
                </Grid>
              ) : (
                <Routes>
                  <Route path="/" exact element={<HomeComponent />} />
                  {/* <Route path="/search" exact element={<Search />} /> */}
                  {routeConfig?.showNLPCapabilities && (
                    <Route path="/upload" exact element={<Upload />} />
                  )}
                  {routeConfig?.showInstanceSegmentation && (
                    <Route
                      path="/computerVision"
                      exact
                      element={<ComputerVision />}
                    />
                  )}
                  {routeConfig?.showVideoInference && (
                    <Route
                      path="/capabilities"
                      exact
                      element={<Capabilities />}
                    />
                  )}
                  {routeConfig?.showTranslator && (
                    <Route path="/translator" exact element={<Translator />} />
                  )}
                  {routeConfig?.showSpeechToText && (
                    <Route
                      path="/speech-to-text"
                      exact
                      element={<SpeechToText />}
                    />
                  )}
                  {routeConfig?.showEdgeCV && (
                    <Route path="/edge-cv" exact element={<EdgeCvPage />} />
                  )}
                  {routeConfig?.showAWSFacialRecognition && (
                    <Route
                      path="/employee-recognition"
                      exact
                      element={<EmployeeRecognitionPage />}
                    />
                  )}
                  {defaultRouteConfig?.showHVTIdentification && (
                    <Route
                      path="/facial-recognition"
                      exact
                      element={<FacialRecognitionPage />}
                    />
                  )}
                </Routes>
              )}
            </Suspense>
          </BrowserRouter>
        </APIProvider>
      </Grid>

      <Grid item xs={11} sm={11} md={12} lg={12} className="footerContainer">
        <Footer />
      </Grid>
    </Grid>
  );
};
