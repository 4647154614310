import { useQuery } from "@tanstack/react-query";

import { getRouteConfig } from "../service/routeConfig";

export const useFetchRouteConfigQuery = () =>
  useQuery({
    queryKey: ["remote-config"],
    queryFn: getRouteConfig,
    refetchOnWindowFocus: false
  });
