import React from "react";
import { StyledEngineProvider } from "@mui/material/styles";
import { ToastContainer, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import AppWrapper from "./template/appTemplate";
import "./assets/sheets/main.scss";

import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

const queryClient = new QueryClient();

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <div className="appContainer">
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          transition={Bounce}
        />
        <QueryClientProvider client={queryClient}>
          <AppWrapper />
        </QueryClientProvider>
      </div>
    </StyledEngineProvider>
  );
}

export default App;
