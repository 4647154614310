import * as React from "react";
import IconButton from "@mui/material/IconButton";
import { Menu, MenuItem } from "@mui/material/";
import { MENU_ICON, TRIANGLE_ICON } from "../assets/icons/IconList";

import { useFetchRouteConfigQuery } from "../queries/routeConfig";

import defaultRouteConfig from "../defaultRouteConfig.json";

const ITEM_HEIGHT = 60;

const activeStyles = {
  background: "#006bb6",
  color: "#fff",
  width: "100%",
  borderRadius: "5px",
};
export default function PrimaryMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { data, isLoading } = useFetchRouteConfigQuery();

  const routeConfig = React.useMemo(() => data ?? defaultRouteConfig, [data]);

  const url = window.location.href;

  return (
    <div className="menuContainer">
      <IconButton
        disabled={isLoading}
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MENU_ICON />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 5.5,
            width: "250px",
            color: "#4299f7",
            paddingBottom: "10px",
            borderBottom: "solid 2px #006bb6",
          },
        }}
      >
        {/* {url.includes("search") === true ? (
          <MenuItem className="floating">
            <a
              style={{
                background: "#006bb6",
                color: "#fff",
                width: "100%",
                borderRadius: "5px",
                padding: "5px",
              }}
              href="/search"
            >
              SEARCH
            </a>
          </MenuItem>
        ) : (
          <MenuItem>
            <a style={{ color: "#006bb6", padding: "5px" }} href="/search">
              SEARCH
            </a>
          </MenuItem>
        )} */}

        {routeConfig?.showNLPCapabilities && (
          <MenuItem>
            <a
              style={Object.assign(
                {
                  color: "#006bb6",
                },
                url.includes("upload") && activeStyles
              )}
              href="/upload"
            >
              NLP Capabilities
            </a>
          </MenuItem>
        )}

        {routeConfig?.showInstanceSegmentation && (
          <MenuItem>
            <a
              style={Object.assign(
                {
                  color: "#006bb6",
                },
                url.includes("computerVision") && activeStyles
              )}
              href="/computerVision"
            >
              Computer Vision
            </a>
          </MenuItem>
        )}

        {routeConfig?.showVideoInference && (
          <MenuItem>
            <a
              style={Object.assign(
                {
                  color: "#006bb6",
                },
                url.includes("capabilities") && activeStyles
              )}
              href="/capabilities"
            >
              Capabilities
            </a>
          </MenuItem>
        )}

        {routeConfig?.showTranslator && (
          <MenuItem>
            <a
              style={Object.assign(
                {
                  color: "#006bb6",
                },
                url.includes("translator") && activeStyles
              )}
              href="/translator"
            >
              Translator
            </a>
          </MenuItem>
        )}
        {routeConfig?.showSpeechToText && (
          <MenuItem>
            <a
              style={Object.assign(
                {
                  color: "#006bb6",
                },
                url.includes("speech-to-text") && activeStyles
              )}
              href="/speech-to-text"
            >
             Speech to Text
            </a>
          </MenuItem>
        )}

        {routeConfig?.showAWSFacialRecognition && (
          <MenuItem>
            <a
              style={Object.assign(
                {
                  color: "#006bb6",
                },
                url.includes("employee-recognition") && activeStyles
              )}
              href="/employee-recognition"
            >
              AWS Facial Recognition
            </a>
          </MenuItem>
        )}
        {routeConfig?.showHVTIdentification && (
          <MenuItem>
            <a
              style={Object.assign(
                {
                  color: "#006bb6",
                },
                url.includes("facial-recognition") && activeStyles
              )}
              href="/facial-recognition"
            >
              HVT Identification
            </a>
          </MenuItem>
        )}
        {routeConfig?.showEdgeCV && (
          <MenuItem>
            <a
              style={Object.assign(
                {
                  color: "#006bb6",
                },
                url.includes("edge-cv") && activeStyles
              )}
              href="/edge-cv"
            >
              Edge CV
            </a>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}
